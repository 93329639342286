
.about-us-btn {
    border-radius: 25px;
    height: 40px;
    padding: 0 45px;
    line-height: 2.2;
    border: 2px solid #6CBEF1;
    z-index: 3;
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    &:hover {
        color: #333 !important;
        background: #6CBEF1;
    }
}